import React, { Component } from "react";

class Links extends Component {
  constructor(props) {
    super(props);
    this.buttonStyle = {
      width: 100 + `px`,
    };
    this.iconSize = {
      fontSize: 24 + `px`,
    };
    this.allLinks = [
      {
        LinkName: "Artefaktas",
        link: "https://www.artefaktas.eu/",
        icon: "bi bi-vector-pen",
      },
      {
        LinkName: "Fantasmus",
        link: "https://fantasmus.artefaktas.eu/",
        icon: "bi bi-vector-pen",
      },
      {
        LinkName: "Podkastas",
        link: "https://anchor.fm/artefaktas",
        icon: "bi bi-headphones",
      },
      {
        LinkName: "PWA",
        link: "https://pwa.artefaktas.eu/",
        icon: "bi bi-android",
      },
      {
        LinkName: "Watermark",
        link: "https://watermark.artefaktas.eu/",
        icon: "bi bi-droplet",
      },
      {
        LinkName: "Nerdy",
        link: "https://nerdy.artefaktas.eu/",
        icon: "bi bi-emoji-sunglasses",
      },
      {
        LinkName: "Creative",
        link: "https://creative.artefaktas.eu/",
        icon: "bi bi-palette",
      },
      {
        LinkName: "Obsidian",
        link: "https://obsidiannotes.artefaktas.eu/",
        icon: "bi bi-gem",
      },
      {
        LinkName: "AltiusDay",
        link: "https://altiusdaycom.wordpress.com/",
        icon: "bi bi-wordpress",
      },
      {
        LinkName: "Publisher",
        link: "https://publisher.artefaktas.eu/",
        icon: "bi bi-book",
      },
      {
        LinkName: "Stories",
        link: "https://amp.artefaktas.eu/",
        icon: "bi bi-film",
      },
      {
        LinkName: "Beeper",
        link: "https://beeper.artefaktas.eu/",
        icon: "bi bi-qr-code-scan",
      },
    ];
  }

  render() {
    return this.allLinks.map((ob) => {
      return (
        <div className="col-4 text-center my-4">
          <a
            href={ob.link}
            rel="noreferrer"
            target="_blank"
            className="btn btn-md btn-dark"
            style={this.buttonStyle}
          >
            <i className={ob.icon} style={this.iconSize}></i>
            <br />
            {ob.LinkName}
          </a>
        </div>
      );
    });
  }
}

export default Links;
