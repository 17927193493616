import React, { Component } from "react";
import Links from "./Links";
import SocialsComponent from "./SocialsComponent";

class MainComponent extends Component {
  constructor(props) {
    super(props);
    this.settings = {
      name: `Artefaktas.eu Portal`,
      socialsName: `Socials`,
      infoDisclaimer: `Some websites work via Tor only`,
    };
  }

  componentDidMount() {}

  render() {
    return (
      <div className="container">
        <div className="row mt-4 py-4 shadow p-3 mb-5 bg-white rounded">
          <h1>{this.settings.infoDisclaimer}</h1>
        </div>
        <div className="row mt-4 py-4 shadow p-3 mb-5 bg-white rounded">
          <h1>{this.settings.name}</h1>
        </div>
        <div className="row py-4 shadow p-3 mb-5 bg-white rounded">
          <Links />
        </div>
        <div className="row mt-4 py-4 shadow p-3 mb-5 bg-white rounded">
          <h1>{this.settings.socialsName}</h1>
        </div>
        <div className="row py-4 shadow p-3 mb-5 bg-white rounded">
          <SocialsComponent />
        </div>
      </div>
    );
  }
}

export default MainComponent;
