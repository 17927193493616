import React, { Component } from "react";

class SocialsComponent extends Component {
  constructor(props) {
    super(props);
    this.buttonStyle = {
      width: 100 + `px`,
    };
    this.iconSize = {
      fontSize: 24 + `px`,
    };
    this.allLinks = [
      {
        LinkName: "Facebook",
        link: "https://www.facebook.com/artefaktasblog",
        icon: "bi bi-facebook",
      },
      {
        LinkName: "Instagram",
        link: "https://www.instagram.com/_artefaktas_/",
        icon: "bi bi-instagram",
      },
      {
        LinkName: "Twitter",
        link: "https://twitter.com/artefaktas",
        icon: "bi bi-twitter",
      },
      {
        LinkName: "TikTok",
        link: "https://www.tiktok.com/@artefaktas",
        icon: "bi bi-tiktok",
      },
    ];
  }

  render() {
    return this.allLinks.map((ob) => {
      return (
        <div className="col-4 text-center my-4">
          <a
            href={ob.link}
            rel="noreferrer"
            target="_blank"
            className="btn btn-md btn-dark"
            style={this.buttonStyle}
          >
            <i className={ob.icon} style={this.iconSize}></i>
            <br />
            {ob.LinkName}
          </a>
        </div>
      );
    });
  }
}

export default SocialsComponent;
